<template>
	<!-- eslint-disable max-len -->
	<MainLayout>
		<b-container class="h-100 p-0">
			<div v-if="fetchingData" class="py-5">
				<b-row class="h-100">
					<div class="col-lg-8 col-xl-9">
						<b-row>
							<div class="col-6 mb-5" v-for="data in 6" :key="data">
								<b-skeleton animation="wave" width="100%" height="150px"></b-skeleton>
							</div>
						</b-row>
					</div>
					<div class="col-lg-4 col-xl-3">
						<b-skeleton animation="wave" width="100%" height="556px"></b-skeleton>
					</div>
				</b-row>
			</div>
			<div v-else id="container" class="page-scroll h-100">
				<div class="h-100 d-flex justify-content-end">
					<b-col class="h-100 col-class">
						<div class="catalog h-100">
							<div class="mobile-cat category-wrap py-3 py-lg-4 mb-0 container">
								<div class="row">
									<div class="col-lg-8 col-xl-9">
										<div class="category d-flex justify-content-between">
											<div class="category-list d-flex categories-list" ref="categories">
												<b-nav
													pills
													card-header
													slot="header"
												>
													<scrollactive class="my-nav" active-class="active"
																				scroll-container-selector=".catalog-inner-wrap" :offset=250
																				highlight-first-item always-track>
														<a
															:key="category.id"
															:class="{'active': index===0}"
															class="cat btn btn-outline-primary category-item mr-2 mr-lg-3 categories-item scrollactive-item"
															:href="'#cat_' + category.id"
															v-for="(category, index) in categories"
														>{{ category.name }}
														</a>
													</scrollactive>
												</b-nav>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="desktop-cat mr-5 position-fixed overflow-auto pr-3">
								<h5 class="text-uppercase bold">Categories</h5>
								<div class="d-flex flex-column" ref="categories">
									<b-nav
										verticle
									>
										<scrollactive class="my-nav d-flex flex-column" active-class="active"
																	scroll-container-selector=".catalog-inner-wrap" :offset=250
																	highlight-first-item always-track>
											<a
												:key="category.id"
												:class="{'active': index===0}"
												class="cat category-item mr-2 mr-lg-3 categories-item scrollactive-item mb-4"
												:href="'#cat_' + category.id"
												v-for="(category, index) in categories"
											>{{ capitalizeFirstLetter(category.name) }}

											</a>
										</scrollactive>
									</b-nav>
								</div>
							</div>
							<b-card-body
								id="nav-scroller"
								ref="content"
								class="catalog-inner-wrap h-100 px-0 pb-0 desktop-content"
							>
								<div class="catalog-inner h-100">
									<section
										v-for="category in categories"
										:key="category.id"
										:id="'cat_' + category.id"
										class="catalog-group section hero is-primary is-fullheight"
									>
										<h5 class="mb-3 mb-lg-4">
											{{ category.name }}
										</h5>

										<div class="catalog-list mb-4">
											<div class="d-flex catalog-list-item">
												<b-col v-for="product in getProductsByCategory(category.id)" :key="product.id" class="py-3 catalog-prod">
													<div class="d-flex justify-content-between bg-white" style="border-radius: 3px; cursor: pointer;" @click="openDetails(product.id)">
														<div class="p-3" style="width: calc(100% - 120px);">
															<h2 style="font-size: 16px; line-height: 18px;">{{ product.name }}</h2>
															<p class="mb-0" style="font-size: 14px; line-height: 18px; opacity: 0.6;">
																{{ product.description }} 
															</p>
														</div> 
														<div class="position-relative" style="min-height: 120px; width: 120px;">
															<p class="position-absolute" style="top: 7px; right: 7px; border-radius: 3px; margin: 0; background-color: rgb(255 255 255 / 85%); padding: 0rem 0.3rem;">{{ currency }} {{ product.price.toFixed(2) }}</p>
															<img 
															    v-if="product['image']"
																:src="product['image']"
																alt="Image"
																class="w-100 h-100" 
																style="object-fit: cover; border-start-end-radius: 3px; border-end-end-radius: 3px;" 
															/>
														</div>
													</div>
												</b-col>
											</div>
										</div>
									</section>
								</div>
							</b-card-body>
						</div>
					</b-col>

					<Sidebar
						ref="sidebar"
						v-show="showSidebar"
						lg="3"
						xl="3"
						:class="{ sidebar: true, 'd-flex flex-column': showSidebar }"
						:items="items"
						:is-remove-item-order="isRemoveItemOrder"
						:is-edit-item-order="isEditItemOrder"
						:remove-from-cart="removeFromCart"
						:is-remove-item-order-show="isRemoveItemOrderShow"
						:checkout="checkout"
						@update-tip="handleUpdateTip"
					/>

					<b-col lg="12" class="fixed-bottom d-lg-none px-0">
						<div class="order-check">
							<b-button variant="primary" @click="checkout" class="w-100" squared size="lg"
												:disabled="items.length===0">
								<strong>Checkout ({{ items.length }})</strong>
								<strong>{{ currency }} {{ grand_total.toFixed(2) }}</strong>
							</b-button>
						</div>
					</b-col>
				</div>
			</div>
		</b-container>

		<ModalProductDetails :product="activeProduct" :store-info="storeInfo" :add-to-cart="addToCart"/>
	</MainLayout>
</template>

<script>
import { mapGetters } from "vuex";
import MainLayout from "../layouts/MainLayout.vue";
import getStoreInfo from "../helpers/apiHelper";
import ModalProductDetails from "@/components/ModalProductDetails";
import Sidebar from "@/components/Sidebar";
import { groupBy } from "lodash";
import indexDbHelper from "@/helpers/indexDbHelper";
// function hideItemsFromMoreDropdown (n) {
// 	console.log("hideItemsFromMoreDropdown", n); // eslint-disable-line no-console
// 	let counter = n;
// 	const dropdownElements = document.getElementsByClassName("show-more-item");
// 	dropdownElements.forEach((el) => {
// 		el.style.display = counter > 0 ? "none" : "block";
// 		counter -= 1;
// 	});
// 	document.getElementsByClassName("category-more")[0].style.display = n === dropdownElements.length ? "none" : "block";
// }

// function reportWindowSize () {
// 	console.log("reportWindowSize"); // eslint-disable-line no-console
// 	console.log(document.getElementsByClassName("catalog")); // eslint-disable-line no-console
// 	const widthOfParent = document.getElementsByClassName("catalog")[0].offsetWidth;
// 	const itemsRightPadding = 30;
// 	const widthShowMore = document.getElementsByClassName("show-more-button")[0].offsetWidth + itemsRightPadding;
// 	let widthItemsSum = widthShowMore;
// 	let shownCategoryItemCounter = 0;
// 	let hideRest = false;
// 	const categoryListElements = document.getElementsByClassName("categories-item");
// 	categoryListElements.forEach((el) => {
// 		if (widthItemsSum + el.offsetWidth >= widthOfParent || hideRest) {
// 			el.style.display = "none";
// 			hideRest = true;
// 		} else {
// 			shownCategoryItemCounter += 1;
// 			el.style.display = "block";
// 			widthItemsSum += el.offsetWidth;
// 		}
// 	});
// 	hideItemsFromMoreDropdown(shownCategoryItemCounter);
// }

export default {
	name: "OrderView",
	components: {
		Sidebar,
		ModalProductDetails,
		MainLayout
	},
	data () {
		return {
			isRemoveItemOrderShow: false,
			storeInfo: {
				name: "",
				phone: "",
				dashboard_link: ""
			},
			categories: [],
			products: [],
			activeProduct: null,
			activeIndex: null,
			orderItems: [],
			width: null,
			fetchingData:false
		};
	},
	async beforeMount () {
		await this.$store.dispatch("setStoreAlias", this.$route.params.storeAlias);
		this.$store.commit("setTipAmount", 0);
		this.$store.dispatch("toggleConfigLoader");
		const storeInfoResponse = await getStoreInfo(this.$route.params.storeAlias);

		if (storeInfoResponse.status === 404) {
			await this.$router.push({ name: "NotFound" });
		} else {
			if(this.items && this.items.length  === 0){
			const items = await indexDbHelper.getDbData('orderItems')
			if(items.length > 0){
				items.forEach(item=>{ this.$store.dispatch("addItem", item);})
			}
		}
			const response = await storeInfoResponse.json();
			this.storeInfo = response;
			await this.$store.dispatch("setStoreName", response.name);
			localStorage.setItem("lastUsedAlias", this.$route.params.storeAlias);
			await this.$store.dispatch("setBaseUrl",response.dashboard_link)
		}
		await this.prefetchData();
		this.$store.dispatch("toggleConfigLoader");
		document.title = this.$store.getters.getStoreName.toString().trim()
			? `${this.$store.getters.getStoreName} - Online Ordering`
			: "Online Ordering";
		[this.activeProduct] = this.products;
	},
	created () {
		window.addEventListener("scroll", this.handleScroll);
	},
	destroyed () {
		window.removeEventListener("scroll", this.handleScroll);
	},
	methods: {
		async checkout () {
			await this.$router.push({ name: "Checkout" });
		},
		capitalizeFirstLetter(string) {
			if (typeof string !== 'string') {
				console.error('Input is not a string:', string);
				return '';
			}
			const result = string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
			return result;
		},
		handleUpdateTip(newTipAmount) {
			if (newTipAmount) this.$store.commit("setTipAmount", newTipAmount)
			else this.$store.commit("setTipAmount", 0)
		},
		async addToCart ({ quantity, modifiers, notes }) {
			const pickedProduct = this.getProductById(this.activeProduct.id);
			if (pickedProduct) {
				const item = {
					...pickedProduct,
					...{
						product: pickedProduct.id,
						cost: pickedProduct.price,
						quantity,
						modifiers,
						discount: 0,
						tax: 0,
						notes
					}
				};
				if (this.activeIndex !== null) {
					item.local_id = this.items[this.activeIndex].local_id
					await this.$store.dispatch("updateItem", { item, index: this.activeIndex });
					this.$forceUpdate();
				} else {
					await this.$store.dispatch("addItem", { ...item, "local_id":(new Date()).toISOString()} );
				}
			}
			this.$bvModal.hide("modal-product-details");
			this.activeIndex = null;
		},
		async removeFromCart () {
			await this.$store.dispatch("removeItem", this.removingItemIndex);
			this.isRemoveItemOrderShow = false;
		},
		openDetails (productId, index = null) {
			const pickedProduct = this.getProductById(productId);
			if (pickedProduct) {
				if (index !== null) {
					this.activeIndex = index;
					this.activeProduct = this.items[index];
				} else {
					this.activeProduct = pickedProduct;
				}
				this.$bvModal.show("modal-product-details");
			}
		},
		getProductById (id) {
			return this.products.find((product) => product.id === id);
		},
		getProductsByCategory (categoryId) {
			return this.products.filter((product) => product.categories.includes(categoryId));
		},
		isEditItemOrder (index) {
			console.log("S>>>: Is Edit item order?"); // eslint-disable-line no-console
			const productId = this.items[index].product;
			this.openDetails(productId, index);
		},
		isRemoveItemOrder (index) {
			if (!this.isRemoveItemOrderShow) {
				this.removingItemIndex = index;
			}
			this.isRemoveItemOrderShow = !this.isRemoveItemOrderShow;
			return this.isRemoveItemOrderShow;
		},
		async prefetchData () {
			// this.$store.commit("setIsLoading", true);
			this.fetchingData = true
			const prefetchedDataResponse = await fetch(`${this.storeInfo.dashboard_link}/api/orderlive/config`);
			if (prefetchedDataResponse.status === 200) {
				const prefetchedData = await prefetchedDataResponse.json();
				if (prefetchedData.dining_options.length === 0) {
					console.error("No dining options available");
					return;
				}
				this.categories = prefetchedData.categories;
				this.products = prefetchedData.products;
				await this.$store.dispatch("setStoreName", prefetchedData.store.name);
				await this.$store.dispatch("isStoreTaxInclusive",prefetchedData.store.tax_option)
				await this.$store.dispatch("setStorePhone", prefetchedData.store.phone);
				await this.$store.dispatch("setStoreLogoUrl", prefetchedData.store.logo);
				await this.$store.dispatch("setStore", prefetchedData.store);
				await this.$store.commit("setAvailableDiningOptions", prefetchedData.dining_options);
				await this.$store.commit("setAvailablePaymentMethods", prefetchedData.payment_types);
				await this.$store.commit("setDiningOption", prefetchedData.dining_options[0].id);
				let { address } = prefetchedData.store;
				address = `${address} ${prefetchedData.store.city}`;
				address = prefetchedData.store.state ? `${address},  ${prefetchedData.store.state}` : address;
				address = prefetchedData.store.zipcode ? `${address}  ${prefetchedData.store.zipcode}` : address;
				await this.$store.dispatch("setStoreAddress", address);
				this.fetchingData = false
			} else {
				console.error("Could not prefetch data"); // eslint-disable-line no-console
			}
			// reportWindowSize();
			// this.$store.commit("setIsLoading", false);
		}
	},
	computed: {
		items () {
			return this.$store.getters.getItems.map((currentItem) => {
				currentItem.modifiersGroupped = groupBy(currentItem.modifiers, "groupName");
				return currentItem;
			});
		},
		totalTax () {
			return this.$store.getters.getTotalTax;
		},
		currency () {
			return this.$store.getters.getCurrencySymbol;
		},
		subtotal () {
			return this.$store.getters.getSubtotal;
		},
		grand_total () {
			return this.subtotal + this.totalTax;
		},
		dining_option () {
			return this.$store.getters.dining_option;
		},
		...mapGetters(["showSidebar"])
	}

};

// jQuery(function ($) {
//     $(document).ready(function() {
//         function becomeSticky(el, padding) {
//             if (el.length) {
//                 el.sticky({
//                     topSpacing: padding
//                 });
//             }
//         }
//         becomeSticky($("#eight-day-sticky-section"), 0);
//     });
// });

// jQuery(function ($) {
//     $(window).scroll(function () {
//         var scrollPos = $(window).scrollTop();
//         $('.tour-details-section').each(function (i) {
//             var topPos = $(this).offset().top;
//             if ((topPos - scrollPos) <= 80) {
//                 $('.tour-details-section-active').removeClass('tour-details-section-active')
//                 $('#eight-day-sticky-section .active-highlight').eq(i).addClass('tour-details-section-active')
//             }
//         })
//     });
// });
//
// $("#change-button").click(function() {
//   $(".outer-container").toggleClass( "active" );
// });

// window.addEventListener("DOMContentLoaded", reportWindowSize, false);
// window.addEventListener("resize", reportWindowSize, true);
</script>
<style scoped>
.col-class {
	@media screen and (max-width: 2200px) {
		flex: 0 0 83.33333333%;
        max-width: 90%;
		margin-right: 10%;
	}
	@media screen and (max-width: 2000px) {
		flex: 0 0 83.33333333%;
        max-width: 83.33333333%;
		margin-right: 10%;
	}
	@media screen and (max-width: 1600px) {
		flex: 0 0 75.33333333%;
        max-width: 75.33333333%;
		/* margin-right: 10%; */
	}
	@media screen and (max-width: 1200px) {
		flex: 0 0 70.33333333%;
        max-width: 70.33333333%;
		/* margin-right: 10%; */
	}
	@media screen and (max-width: 990px) {
		flex: 0 0 100%;
        max-width: 100%;
		margin-right: 0;
	}
}
.desktop-cat {
	top: 205px;
	width: 17%;
	left: 2%;
	height: 75%;
	@media screen and (max-width: 998px) {
		display: none;
	}
}
.mobile-cat {
	@media screen and (min-width: 998px) {
		display: none;
	}
}
.desktop-content{
	@media screen and (max-width: 990px) {
		display: flex;
	}
	@media screen and (max-width: 768px) {
		margin-left: 0;
		
	}
}
.catalog-list {
	@media screen and (max-width: 2000px) {
		margin-right: 0;	
		margin-left: 0;	
	}
	@media screen and (max-width: 450px) {
		margin-right: -15px;	
		margin-left: -15px;	
	}
}
.catalog-list-item {
	@media screen and (max-width: 2200px) {
		flex-wrap: wrap;
	}
	@media screen and (max-width: 2000px) {
		flex-wrap: wrap;
	}
	@media screen and (max-width: 1600px) {
		flex-wrap: wrap;
		flex-direction: column;
	}
	@media screen and (max-width: 990px) {
		flex-direction: row;
	}
}
.catalog-prod {
	@media screen and (max-width: 2200px) {
		flex: 0 0 50%;
		max-width: 50%;
	}
	@media screen and (max-width: 2000px) {
		flex: 0 0 50%;
		max-width: 50%;
	}
	@media screen and (max-width: 1600px) {
		flex: 0 0 75.33333333%;
		max-width: 75.33333333%;
	}
	@media screen and (max-width: 1200px) {
		flex: 0 0 70.33333333%;
		max-width: 70.33333333%;
	}
	@media screen and (max-width: 990px) {
		flex: 0 0 50%;
		max-width: 50%;
	}
	@media screen and (max-width: 700px) {
		flex: 0 0 100%;
		max-width: 100%;
	}
}
</style>