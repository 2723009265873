<template>
	<b-col>
		<h5 class="mb-3 mb-lg-4">
			Your Order
		</h5>

		<div v-if="items.length === 0">
			<div class="empty text-center">
				<div class="d-flex flex-column justify-content-center h-100">
					<b-img src="../assets/images/sidebar-empty.svg" fluid alt="Fluid image"></b-img>
					<h5>
						<strong>Your cart is empty.</strong>
					</h5>
					<p>
						Please add items to get started.
					</p>
				</div>
			</div>
			<div class="copyright">
				powered by <a href="https://biyopos.com/">Biyo Pos</a>
			</div>
		</div>
		<div v-else-if="items.length > 0" class="order">
			<div class="order-list">
				<div v-for="(item, index) in items" :key="index" class="order-item">
					<div class="order-item-top d-flex justify-content-between">
						<div class="order-quant">
							<span>{{ item.quantity }}</span>
						</div>
						<h6 class="order-title">
							{{ item.name }}
						</h6>
						<div class="order-price ml-auto">{{currency}} {{ countProductPrice(item).toFixed(2) }}</div>
					</div>

					<div class="order-item-body">
						<span
							v-for="(currentModifiers, modifierGroupName) in item.modifiersGroupped || []"
							:key="modifierGroupName"
							class="mb-2"
						>
							<p>
								<strong>{{ modifierGroupName }}</strong>
							</p>
							<p v-for="currentModifier in currentModifiers || []" :key="currentModifier.id" class="mb-2">
								{{ currentModifier.name }}
							</p>
							<p></p>
						</span>
					</div>

					<div class="order-item-settings">
						<a href="#" class="link text-primary" @click="isEditItemOrder(index)">
							<span>Edit</span>
						</a>
						<a href="#" class="link text-danger" @click="openRemovePopup(index)">
							<span>Remove</span>
						</a>
					</div>
				</div>
			</div>

			<div class="order-bottom bg-white">
				<div class="order-total">
					<div class="order-total-tr">
						<div class="order-item-top">
							<span>Tip</span>
							<div v-if="!isCustomTip" class="tip mt-2 d-flex justify-content-between">
								<div class="mr-2 tip-con">
								<button
									class="border p-2 btn custom-box"
									:class="{ 'active': selectedTip === 10 }"
									@click="addTip(10)"
								>10% <small :class="{ 'active': selectedTip === 10 }" >${{ (subtotal * 0.10).toFixed(2) }}</small></button>
								</div>
								<div class="mr-2 tip-con">
								<button
									class="border p-2 btn custom-box"
									:class="{ 'active': selectedTip === 15 }"
									@click="addTip(15)"
								>15% <small :class="{ 'active': selectedTip === 15 }">${{ (subtotal * 0.15).toFixed(2) }}</small></button>
								</div>
								<div class="mr-2 tip-con">
								<button
									class="border p-2 btn custom-box"
									:class="{ 'active': selectedTip === 20 }"
									@click="addTip(20)"
								>20% <small :class="{ 'active': selectedTip === 20 }">${{ (subtotal * 0.20).toFixed(2) }}</small></button>
								</div>
								<div class="mr-2 tip-con">
								<button class="border p-2 btn custom-b" @input="handleCustomTipChange" @click="openTipInput()">Custom</button>
								</div>
							</div>

							<!-- Custom tip input -->
							<div v-if="isCustomTip" class="mt-2">
								<label for="customTip" class="form-label custom-label">Custom Amount</label>
								<div class="d-flex custom-tip">
									<input class="tip-input" min="0" type="number" v-model="customTipAmount" placeholder="$0.00" ref="customTipInput" />
									<svg class="icon icon-close mr-1 tip-cross" @click="openTipInput()">
										<use xlink:href="#icon-close"></use>
									</svg>
								</div>
							</div>
						</div>
					</div>
					<div class="order-total-tr">
						<div class="order-item-top d-flex justify-content-between">
							<div>
								Subtotal
							</div>
							<div class="order-price">{{currency}} {{ subtotal.toFixed(2) }}</div>
						</div>
					</div>					
					<div class="order-total-tr" v-if="tipTotal">
						<div class="order-item-top d-flex justify-content-between">
							<div>
								Tip
							</div>
							<div class="order-price">{{currency}} {{ tipTotal.toFixed(2) }}</div>
						</div>
					</div>
					<div class="order-total-tr">
						<div class="order-item-top d-flex justify-content-between">
							<div>
								Tax
							</div>
							<div class="order-price">{{currency}} {{ totalTax.toFixed(2) }}</div>
						</div>
					</div>
				</div>

				<div class="order-check d-none d-lg-block">
					<b-button @click="checkout" variant="primary" class="w-100" squared size="lg">
						<strong>Checkout ({{ items.length }})</strong>
						<strong>{{currency}} {{ grand_total.toFixed(2) }}</strong>
					</b-button>
				</div>
			</div>
		</div>

		<div v-if="isRemoveItemOrderShow" class="mask d-flex flex-column justify-content-center">
			<h4 class="mask-title">Remove {{ itemRemoving.name }} from your cart?</h4>
			<div class="mask-settings d-flex justify-content-center">
				<b-button variant="outline-light" text-variant="white" @click="isRemoveItemOrder">NO</b-button>
				<b-button variant="light" text-variant="dark" class="ml-3" @click="removeFromCart()">YES</b-button>
			</div>
		</div>
	</b-col>
</template>

<script>
import sumBy from "lodash/sumBy";

export default {
	name: "Sidebar",
	data () {
		return {
			itemRemoving: null,
			isCustomTip: false,
			selectedTip: null,
			customTipAmount: null,
		};
	},
	props: {
		items: { type: Array, default: () => [] },
		isEditItemOrder: { type: Function, default: null },
		isRemoveItemOrder: { type: Function, default: null },
		removeFromCart: { type: Function, default: null },
		isRemoveItemOrderShow: { type: Boolean, default: false },
		checkout: { type: Function, default: null },
		tipAmount: {type: Number, default: null}
	},
	computed: {
		subtotal () {
			return this.$store.getters.getSubtotal;
		},
		totalTax () {
			return this.$store.getters.getTotalTax;
		},
		tipTotal() {
			if (this.selectedTip) {
				return (this.subtotal * this.selectedTip) / 100;
			} else {
				return parseFloat(this.customTipAmount);
			}
		},
		grand_total () {
			if (this.tipTotal) {
				return this.subtotal + this.totalTax + this.tipTotal;
			} else {
				return this.subtotal + this.totalTax
			}
		},
		currency () {
			return this.$store.getters.getCurrencySymbol;
		},
	},
	watch: {
		customTipAmount() {
			this.handleCustomTipChange();
		}
	},
	methods: {
		countProductPrice (product) {
			const modTotal = sumBy(product.modifiers, (mod) => mod.price);
			return (product.price + modTotal) * product.quantity;
		},
		openRemovePopup (index) {
			this.itemRemoving = this.items[index];
			this.isRemoveItemOrder(index);
		},
		addTip(percentage) {
			if (this.selectedTip === percentage)this.selectedTip = null;
			else {
				this.selectedTip = percentage;
				this.customTipAmount = null;
				this.isCustomTip = false;
			}
			this.emitTipAmount();
		},
		handleCustomTipChange() {
			this.selectedTip = null;
			this.emitTipAmount();
		},
		openTipInput() {
			this.selectedTip = null;
			this.isCustomTip = !this.isCustomTip;
			if (this.isCustomTip) {
				this.$nextTick(() => {
				this.$refs.customTipInput.focus();
				});
			}
			if (!this.isCustomTip) {
			this.customTipAmount = null;
			}
			this.emitTipAmount();
		},
		emitTipAmount() {
			const tipAmount = this.tipTotal; 
			this.$emit('update-tip', tipAmount);
		},
	}
};
</script>

<style lang="scss" scoped>
.order-bottom {
	@media screen and (max-width: 990px) {
		bottom: 175px !important;
	}
}
.tip-con:hover .btn {
	background-color: #2196f3;
	color: white;
	small {
		color: white
	}
}
small {
	color: gray;
}
.active {
	background: #2196f3;
	color: white
}
.order-list {
	height: auto;
	overflow-y: auto;
}
.tip-input {
	border: none;
	width: 100%;
	padding-bottom: 10px;
	outline: none;
}
.custom-tip {
	border-bottom: 2px solid #2196f3;
}
.tip-input::-webkit-outer-spin-button,
.tip-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.tip-cross {
    cursor: pointer;
	font-size: 12px;
	margin-top: 6px;
}
.tip-cross:hover {
	background: #d3d3d352;
}
.custom-label {
	color: #2196f3;
	font-size: 14px;
}
.custom-box {
	width: 70px;
	height: 70px;
}
.custom-b {
	width: 100px;
	height: 70px;
}
@media screen and (max-width: 1320px) {
.custom-box {
	width: 58px;
	height: 70px;
}
.custom-b {
	width: 80px;
	height: 70px;
}
}

</style>
